@font-face {
  font-family: "Sofia Pro";
  src: url("/_next-public/fonts/sophia-pro/light/font.woff2") format("woff2"),
       url("/_next-public/fonts/sophia-pro/light/font.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("/_next-public/fonts/sophia-pro/light-italic/font.woff2") format("woff2"),
       url("/_next-public/fonts/sophia-pro/light-italic/font.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("/_next-public/fonts/sophia-pro/regular/font.woff2") format("woff2"),
       url("/_next-public/fonts/sophia-pro/regular/font.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("/_next-public/fonts/sophia-pro/regular-italic/font.woff2") format("woff2"),
       url("/_next-public/fonts/sophia-pro/regular-italic/font.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("/_next-public/fonts/sophia-pro/bold/font.woff2") format("woff2"),
       url("/_next-public/fonts/sophia-pro/bold/font.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sofia Pro";
  src: url("/_next-public/fonts/sophia-pro/bold-italic/font.woff2") format("woff2"),
       url("/_next-public/fonts/sophia-pro/bold-italic/font.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
